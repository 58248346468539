import React, { ClipboardEventHandler, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { CheckCircleFilled, EyeInvisibleOutlined, EyeOutlined } from 'antd/icons';
import { motion } from 'framer-motion';
import UnstyledButton from '@components/UI/UnstyledButton';
import { Input } from 'antd';
import { ANSI_SYMBOL, SPECIAL_CHARS_REGEX } from '@lib/passwordRules';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import { debounce } from 'lodash';

type RuntimeCheckProps = {
  description: string;
  ok: boolean;
};

const ticksAndCrosses = new Map([
  [false, { character: '✗', color: 'red' }],
  [true, { character: '✓', color: 'green' }]
]);

const StyledLi = styled(motion.li)<Pick<RuntimeCheckProps, 'ok'>>`
  display: flex;
  align-items: center;
  gap: 0.5em;
  span {
    font-family: monospace;
    color: ${({ ok }) => ticksAndCrosses.get(ok)?.color};
  }
`;

const RuntimeCheck = ({ description, ok }: RuntimeCheckProps) => (
  <StyledLi {...{ ok }}>
    <span>{ticksAndCrosses.get(ok)?.character}</span> {description}
  </StyledLi>
);

type PasswordValidatorProps = {
  password: string;
  setValidity: React.Dispatch<React.SetStateAction<boolean>>;
  isFocussed: boolean;
};

const StyledPasswordValidatorDiv = styled.div`
  color: var(--productGray);
  margin: 0.5em 0;
  p {
    margin: 0.5em 0;
  }
  ul {
    list-style: none;
    margin: 0.5em 0;
    padding: 0 0 0 1em;
    color: var(--black);
  }
`;

function PasswordValidator({ password, isFocussed, setValidity }: PasswordValidatorProps) {
  const dispatch = useDispatch();
  const [tooShort, setTooShort] = useState(false);
  const [missingUppercase, setMissingUppercase] = useState(false);
  const [missingLowercase, setMissingLowercase] = useState(false);
  const [missingSpecial, setMissingSpecial] = useState(false);
  const [missingDigit, setMissingDigit] = useState(false);
  const [containsSpace, setContainsSpace] = useState(false);
  const [isGoodPassword, setIsGoodPassword] = useState(false);

  const checkPassword = async (passwd: string) => {
    const result = await dispatch(actions.checkPassword(passwd), false);

    return result?.status !== 412;
  };

  const handleValidPassword = async (passwd: string) => {
    const isGoodPassword = await checkPassword(passwd);
    setIsGoodPassword(isGoodPassword);
    setValidity(isGoodPassword);
  };

  const handleInvalidPassword = () => {
    setIsGoodPassword(false);
    setValidity(false);
  };

  const checkIfPasswordValid = async (passwd: string) => {
    const isValidPassword = checkBasicValidity(passwd);
    if (!passwd || !isValidPassword) {
      handleInvalidPassword();
      return;
    }

    await handleValidPassword(passwd);
  };

  const debouncedCheckIfPasswordValid = useCallback(
    debounce((passwd) => checkIfPasswordValid(passwd), 400),
    []
  );

  const checkBasicValidity = useCallback((passwd = '') => {
    const tooShort = passwd.length < 8;
    const missingDigit = !/\d/.test(passwd);
    const missingUppercase = !/[A-Z]/.test(passwd);
    const missingLowercase = !/[a-z]/.test(passwd);
    const missingSpecial = !SPECIAL_CHARS_REGEX.test(passwd);
    const hasSpace = /\s/.test(passwd) || passwd.length === 0;

    setMissingUppercase(missingUppercase);
    setMissingLowercase(missingLowercase);
    setMissingSpecial(missingSpecial);
    setMissingDigit(missingDigit);
    setTooShort(tooShort);
    setContainsSpace(hasSpace);

    return !(tooShort || missingDigit || missingLowercase || missingSpecial || missingUppercase || hasSpace);
  }, []);

  useEffect(() => {
    debouncedCheckIfPasswordValid(password || '');
  }, [checkBasicValidity, debouncedCheckIfPasswordValid, password]);

  // After blurring out of the password field, don't wait for a debounce, immediately trigger a password check
  useEffect(() => {
    if (!isFocussed && password) {
      checkIfPasswordValid(password).then();
    }
  }, [checkBasicValidity, checkIfPasswordValid, password, isFocussed]);

  return (
    <StyledPasswordValidatorDiv>
      <p>Should contain at least:</p>
      <ul>
        <RuntimeCheck ok={!tooShort} description="8 characters" />
        <RuntimeCheck ok={!containsSpace} description="but exclude spaces" />
        <RuntimeCheck ok={!missingUppercase} description="one uppercase character" />
        <RuntimeCheck ok={!missingLowercase} description="one lowercase character" />
        <RuntimeCheck ok={!missingDigit} description="one digit character" />
        <RuntimeCheck ok={!missingSpecial} description="one special character" />
        <RuntimeCheck ok={isGoodPassword} description="is not common password" />
      </ul>
      <p>Hint: Click the “eye” to display the password text as you type</p>
    </StyledPasswordValidatorDiv>
  );
}

const Ticked = styled(CheckCircleFilled)<{ ticked: boolean }>`
  opacity: ${({ ticked }) => (ticked ? 1 : 0)};
  color: green;
`;

type PasswordIconsProps = {
  visible: boolean;
  valid: boolean;
  onClick: (event: React.MouseEvent) => void;
};

const PasswordIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const PasswordIcons = ({ visible, valid, onClick = () => {} }: PasswordIconsProps) => {
  return (
    <UnstyledButton type="button" onClick={onClick} data-testid="show">
      <PasswordIconContainer>
        <Ticked ticked={valid} />
        <span>{visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}</span>
      </PasswordIconContainer>
    </UnstyledButton>
  );
};

type PasswordProps = {
  onChange: (str: string) => void;
  setValid?: React.Dispatch<React.SetStateAction<boolean>>;
};

function Password({ onChange = () => {}, setValid = () => {} }: PasswordProps) {
  const [passwordValid, setPasswordValid] = useState(false);
  const [password, setPassword] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [illegalCharacterPressed, setIllegalCharacterPressed] = useState(false);
  const [isFocussed, setIsFocussed] = useState(false);

  useEffect(() => {
    setValid(passwordValid);
  }, [passwordValid]);

  useEffect(() => {
    if (illegalCharacterPressed) {
      const timer = setInterval(() => setIllegalCharacterPressed(false), 250);

      return () => clearInterval(timer);
    }

    return () => {};
  }, [illegalCharacterPressed]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassword(e.target.value);
    onChange(e.target.value);
  };

  const onKeyDown: React.KeyboardEventHandler = (event) => {
    const { key } = event;

    if (key.length === 1) {
      if (!ANSI_SYMBOL.test(key)) {
        event.preventDefault();
        setIllegalCharacterPressed(true);
      }
    }
  };

  const onPaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
    const text = event.clipboardData.getData('text');
    if (text) {
      if (!ANSI_SYMBOL.test(text)) {
        event.preventDefault();
        setIllegalCharacterPressed(true);
      }
    }
  };

  return (
    <div>
      <Input
        type={visiblePassword ? 'text' : 'password'}
        value={password}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        onChange={handleChange}
        onFocus={() => setIsFocussed(true)}
        onBlur={() => setIsFocussed(false)}
        className="login-input"
        placeholder="Password"
        autoComplete="new-password"
        suffix={
          <PasswordIcons
            visible={visiblePassword}
            valid={passwordValid}
            onClick={() => setVisiblePassword((v) => !v)}
          />
        }
        data-testid="password-input"
      />
      <div style={{ overflow: 'hidden', height: '100%' }}>
        <PasswordValidator password={password} isFocussed={isFocussed} setValidity={setPasswordValid} />
      </div>
    </div>
  );
}

export default Password;
