import { ComponentProps } from 'react';
import { Form } from 'antd';

export const SPECIAL_CHARS_REGEX = /[!@#$%^&*()\-_=+{}[\]|\\:;"'<>,.?/~`]/;
export const ANSI_SYMBOL = /^[A-Za-z0-9$.*[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]+$/;

export const passwordRules: ComponentProps<typeof Form.Item>['rules'] = [
  {
    required: true,
    message: 'Please input your password!'
  },
  {
    min: 8,
    message: 'Password must be at least 8 characters long.'
  },
  {
    max: 99,
    message: 'Password must be shorter than 99 characters long.'
  },
  {
    pattern: /[a-z]/,
    message: 'Please include a lowercase character'
  },
  {
    pattern: /[A-Z]/,
    message: 'Please include a uppercase character'
  },
  {
    pattern: /[0-9]/,
    message: 'Please include a digit'
  },
  {
    validator: (_, password: string) => {
      return SPECIAL_CHARS_REGEX.test(password) || !password
        ? Promise.resolve()
        : Promise.reject(
            Error(
              'Please include a special character from the set: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -'
            )
          );
    }
  },
  {
    validator: (_, password: string) => {
      return password?.includes(' ') ? Promise.reject(Error('Please do not include spaces')) : Promise.resolve();
    }
  },
  {
    validator: (_, password: string) => {
      const invalid = password
        ?.split('')
        .filter((c) => c !== ' ')
        .map((c) => c.charCodeAt(0))
        .find((n) => n <= 32 || n > 126);
      return invalid
        ? Promise.reject(Error(`Please do not use complex characters or emoji: "${String.fromCharCode(invalid)}"`))
        : Promise.resolve();
    }
  }
];

export default passwordRules;
